<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="friendLinkForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="友情链接名称" prop="name">
          <a-input
            placeholder="请输入"
            v-model="info.name"
            :maxLength="100"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="友情链接网址" prop="link">
          <a-input
            placeholder="请输入"
            v-model="info.link"
            :maxLength="300"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="logo图片">
          <a-upload
            accept="image/png,image/jpeg"
            :action="$api.file.uploadFile"
            listType="picture-card"
            :beforeUpload="beforeUpload"
            :fileList="fileList"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="info.logo" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch
            v-model="info.status"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "friendlinkInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      info: {
        status: true,
      },
      rules: {
        name: [
          { required: true, message: "请输入友情链接名称", trigger: "blur" },
        ],
        link: [
          { required: true, message: "请输入友情链接网址", trigger: "blur" },
        ],
      },
      isloading: false,
      loadingTip: "加载中...",
      fileList: [],
      previewVisible: false,
    };
  },
  mounted() {
    _this = this;
    if (_this.infos.id != 0) {
      //编辑
      _this.getInfo();
    }
  },
  methods: {
    /**数据回显 */
    getInfo() {
      _this.isloading = true;
      _this.$api.friendlink
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.info.status = _this.$commen.changeIntBoolean(res.data.status);
            let pics = res.data.logo.split(",");
            for (let i = 0; i < pics.length; i++) {
              _this.fileList.push({
                uid: i,
                name: "广告" + (i + 1) + ".png",
                status: "done",
                url: pics[i],
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.friendLinkForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let fromData = { ..._this.info };
          fromData.status = _this.$commen.changeBooleanInt(_this.info.status);
          if (_this.infos.id) {
            //编辑
            fromData.id = _this.infos.id;
          }
          //新增
          _this.$api.friendlink
            .saveOrUpdate(fromData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.info.logo = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        _this.fileList = [];
        _this.info.logo = "";
      }
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
  },
};
</script>
<style>
.anticon-plus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
.anticon-minus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
</style>