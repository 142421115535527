<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="名称">
              <a-input placeholder="请输入" v-model="search.name" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear>
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">已禁用</a-select-option>
                <a-select-option value="1">已启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo(0)"
          type="primary"
          :roles="['友情链接-添加']"
        />
        <pxkeji-btn
          name="批量启用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="batchUpdateStatus(1)"
          :roles="['友情链接-启用禁用']"
        />
        <pxkeji-btn
          name="批量禁用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="batchUpdateStatus(0)"
          :roles="['友情链接-启用禁用']"
        />
        <pxkeji-btn
          name="批量删除"
          :disabled="selectRows.length == 0"
          @click="batchDelete"
          type="danger"
          class="pxkeji_basic_btn"
          :roles="['友情链接-删除']"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <template slot="logo" slot-scope="logo">
          <img :src="logo" alt="" style="width: 150px" />
        </template>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status == 1" color="green">已启用</a-tag>
          <a-tag v-else color="red">已禁用</a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action.id)"
            size="small"
            type="primary"
            :roles="['友情链接-编辑']"
          />
          <a-popconfirm
            title="是否确认禁用此友情链接"
            @confirm="updateStatus(action.id, 0)"
            @cancel="modalClose"
            v-if="action.status == 1"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="禁用"
              size="small"
              type="danger"
              :roles="['友情链接-启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm
            title="是否确认启用此友情链接"
            @confirm="updateStatus(action.id, 1)"
            @cancel="modalClose"
            v-else
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="启用"
              size="small"
              type="default"
              :roles="['友情链接-启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm @confirm="delInfo(action.id)" @cancel="modalClose">
            <div slot="title">是否确认删除此友情链接？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :loading="isloading"
              :roles="['友情链接-删除']"
            />
          </a-popconfirm>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <pxkeji-btn
                  name="上移"
                  size="small"
                  @click="upOrDown(action.id, 1)"
                  :roles="['友情链接-编辑']"
                />
              </a-menu-item>
              <a-menu-item key="2">
                <pxkeji-btn
                  name="下移"
                  size="small"
                  @click="upOrDown(action.id, 0)"
                  :roles="['友情链接-编辑']"
                />
              </a-menu-item>
            </a-menu>
            <a-button size="small" type="link"> ... </a-button>
          </a-dropdown>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="50%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <FriendlinkInfo @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import FriendlinkInfo from "./friendlinkInfo.vue"; //新增编辑

const columns = [
  {
    dataIndex: "name",
    align: "center",
    title: "友情链接名称",
    ellipsis: true,
  },
  {
    dataIndex: "link",
    align: "center",
    title: "友情链接网址",
    ellipsis: true,
  },
  {
    dataIndex: "logo",
    align: "center",
    title: "logo图片",
    width: 260,
    scopedSlots: { customRender: "logo" },
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 220,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 260,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "friendlink",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      search: {
        name: "",
        status: "",
      },
      selectRows: [], //选中项编号
      selectRowId: "",
      isloading: false,
    };
  },
  components: {
    FriendlinkInfo,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 293;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.friendlink
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.name,
          _this.search.status
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
      _this.cancelSelect();
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    upOrDown(id, up) {
      _this.isloading = true;
      _this.$api.friendlink
        .upOrDown(id, up)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      _this.addedit.visible = true;
      _this.addedit.title = id != 0 ? "编辑" : "新增";
      _this.addedit.info.id = id;
    },
    /*批量启用禁用 */
    batchUpdateStatus(status) {
      let msg = "禁用";
      if (status == 1) {
        msg = "启用";
      }
      _this.$confirm({
        title: "提示",
        content: `确定要${msg}选择的友情链接吗?`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.updateStatus(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    /**单个启用禁用 */
    updateStatus(ids, status) {
      _this.isloading = true;
      _this.$api.friendlink
        .updateStatus(ids, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    //批量删除
    batchDelete() {
      _this.$confirm({
        title: "提示",
        content: "确定要删除选择的友情链接吗?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.delInfo(_this.selectRows.join(","));
        },
        onCancel() {},
      });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.friendlink
        .deleteFriendlink(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = "";
      }, 3000);
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

